import React from 'react';
import { reduxForm, FieldArray } from 'redux-form';
import { withRouter } from "react-router";
import { connect } from 'react-redux';
import {
  RadioButtonGroup, RadioGroup, Button, Input, FormGroupList
} from 'tyb';
import { TablePopConfirm } from 'ucode-components';
import { NewSelect  } from 'ucode-components';
import { FormGroupField } from 'tyb';
const { setCurrentStep, setCreateStep } = iceStarkData.store.get('commonAction')?.codePrintingConfig;
const { loadAddressBookSelections } = iceStarkData.store.get('commonAction')?.addressBooks;
const { loadIpcSelections } = iceStarkData.store.get('commonAction')?.certificate;
const { loadSelections } = iceStarkData.store.get('commonAction')?.factory;
const { loadUsersSelections } = iceStarkData.store.get('commonAction')?.users;

const { domainConfigsByEseId } = iceStarkData.store.get('commonAction')?.domainConfigs;
import * as validation from './validation';
import { trims } from 'ucode-utils';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';

const requiredByInput = validation.required('请填写')

const requiredBySelect = validation.required('请选择')
const workflow = (value, values, props, name) => {
  let list = values[name.split('.')[0].split('[')[0]][name.split('.')[0].split('[')[1].split(']')[0]] ? values[name.split('.')[0].split('[')[0]][name.split('.')[0].split('[')[1].split(']')[0]].ipcId : ''

  if (list && !value) {
  }
  else if (!value) {
    return '码包接收人与码包拉取设备，至少选其一'
  }

}
const workflowIcp = (value, values, props, name) => {
  // 2023-08-25 迎驾贡注释
  // let list = values[name.split('.')[0].split('[')[0]][name.split('.')[0].split('[')[1].split(']')[0]] ? values[name.split('.')[0].split('[')[0]][name.split('.')[0].split('[')[1].split(']')[0]].workflowNodes[2].signerCode : ''
  // if (list && !value) {
  // }
  // else if (!value) {
  //   return '码包接收人与码包拉取设备，至少选其一'
  // }

  if (!value) {
    return '码包接收人与码包拉取设备，至少选其一'
  }
}

const maxLength = validation.maxLength(30)




const renderAudits = ({ change, fields, usersSelectionsOne, usersSelectionsTwo, addressBookSelections, factorySelector, ipcSelectionsList, initialValues, handleFactoryChange }) => {
  // console.log('fields: ', initialVchangealues);
  if (fields.getAll()) {
    fields.getAll().map((item) => {
      if (!item.factoryId) {
        ipcSelectionsList = [];
      }
    });
  }
  return <div>

    {
      fields.map((audit, index) => (
        <div key={index} >
          <div className="Audit">
            <div className="Audit-delete" onClick={() => {
              fields.remove(index);
            }}>删除</div>
            <FormGroupList>
              <FormGroupField
                name={`${audit}.name`}
                label="审核流程名称"
                component={Input}
                validate={[requiredByInput, maxLength]}
              >
                <div className="extraText">支持中文、英文字母、中划线和下划线，长度不超过30个字符</div>

              </FormGroupField>
            </FormGroupList>
            <div>
              <div style={{ display: 'inline-table' }}>
                <FormGroupField
                  className="specClass"
                  style={{ minWidth: '110px', width: '110px', marginLeft: '11px' }}
                  name={`${audit}.workflowNodes[0].signerCode`}
                  label="码包申请人"
                  component={NewSelect}
                  needText
                  placeholder={'请选择'}
                  list={usersSelectionsOne}
                  validate={[requiredBySelect]}
                  onChange={(val) => { if(val.value==-1) {change(`configWorkflowTemplateDTOs[${index}].workflowNodes[1].signerCode`, '');change(`configWorkflowTemplateDTOs[${index}].workflowNodes[2].signerCode`, '');if(initialValues != "IPC_SECRECY"){change(`configWorkflowTemplateDTOs[${index}].workflowNodes[3].signerCode`, '')}} }}
                />
              </div>
              <div style={{ display: 'inline-table' }}>
                {!(fields.get(index).workflowNodes[0].signerCode && fields.get(index).workflowNodes[0].signerCode.value && fields.get(index).workflowNodes[0].signerCode.value == -1) && <FormGroupField
                  className="specClass2"
                  style={{ minWidth: '110px', width: '110px' }}
                  name={`${audit}.workflowNodes[1].signerCode`}
                  label="码包审核人"
                  component={NewSelect}
                  needText
                  placeholder={'请选择'}
                  list={usersSelectionsTwo}
                  validate={[requiredBySelect]}
                />}
                {fields.get(index).workflowNodes[0].signerCode && fields.get(index).workflowNodes[0].signerCode.value == -1 &&
                  <FormGroupList>
                    <span>码包审核人</span>
                    <span> : </span>
                    <span className="extraText" style={{paddingRight: '15px' }}>无（免审）</span>
                  </FormGroupList>}
              </div>
              <div style={{ display: 'inline-table' }}>
                {!(fields.get(index).workflowNodes[0].signerCode && fields.get(index).workflowNodes[0].signerCode.value && fields.get(index).workflowNodes[0].signerCode.value == -1) &&<FormGroupField
                  className="specClass2"
                  style={{ minWidth: '110px', width: '110px' }}
                  name={`${audit}.workflowNodes[2].signerCode`}
                  label="码包接收人"
                  component={NewSelect}
                  needText
                  placeholder={'请选择'}
                  list={addressBookSelections}
                  validate={initialValues == 'IPC_SECRECY' ? workflow : [requiredBySelect]}
                />}
                {fields.get(index).workflowNodes[0].signerCode && fields.get(index).workflowNodes[0].signerCode.value == -1 &&
                  <FormGroupList>
                    <span>码包接收人</span>
                    <span> : </span>
                    <span className="extraText" style={{paddingRight: '15px' }}>ISV账号</span>
                  </FormGroupList>}
              </div>

              {initialValues != "IPC_SECRECY" && <div style={{ display: 'inline-table' }}>
                {!(fields.get(index).workflowNodes[0].signerCode && fields.get(index).workflowNodes[0].signerCode.value && fields.get(index).workflowNodes[0].signerCode.value == -1) &&<FormGroupField
                  className="specClass2"
                  style={{ minWidth: '110px', width: '110px' }}
                  name={initialValues != "IPC_SECRECY" ? `${audit}.workflowNodes[3].signerCode` : ""}
                  label="码包解压密码获取人"
                  component={NewSelect}
                  needText
                  placeholder={'请选择'}
                  list={addressBookSelections}
                  validate={[requiredBySelect]}
                />}
                {fields.get(index).workflowNodes[0].signerCode && fields.get(index).workflowNodes[0].signerCode.value == -1 &&
                  <FormGroupList>
                    <span>码包解压密码获取人</span>
                    <span> : </span>
                    <span className="extraText" style={{paddingRight: '15px' }}>ISV账号</span>
                  </FormGroupList>}
              </div>}
            </div>
            <FormGroupList>
              <FormGroupField
                style={{ width: 330 }}
                name={`${audit}.factoryId`}
                label="码包接收工厂"
                component={NewSelect}
                needText
                placeholder={'请选择'}
                list={factorySelector}
                validate={[requiredBySelect]}
                onChange={(val) => { handleFactoryChange(val); change(`${audit}.ipcId`, '') }}

              />

            </FormGroupList>
            <FormGroupList>
              {initialValues == "IPC_SECRECY" && <div style={{ display: 'inline-table' }}>
                <FormGroupField
                  style={{ width: 330 }}
                  name={`${audit}.ipcId`}
                  label="码包拉取设备"
                  component={NewSelect}
                  needText
                  placeholder={'请选择'}
                  list={ipcSelectionsList || []}
                  validate={workflowIcp}
                />
              </div>}
            </FormGroupList>

            {initialValues == "IPC_SECRECY" && <FormGroupList>
              <span>码包解压密码获取人</span>
              <span> : </span>
              <span className="extraText">由安装证书的软件自行解码无需接收人</span>
            </FormGroupList>}

          </div>
          {index !== 9 && <div className="line" ></div>}

        </div>
      ))
    }
    {fields.length < 50 && <div className="addBlock" onClick={() => {
      fields.push(
        {
          workflowNodes: [
            {
              nodeCategory: 'USER',
              nodeType: 'PROCESS_START',
            },
            {
              nodeCategory: 'USER',
              nodeType: 'PROCESS_AUDIT',
            },
        
            {
              nodeCategory: 'USER',
              nodeType: 'PACKET_RECEIVER',
            },
            {
              nodeCategory: 'USER',
              nodeType: 'PASSWORD_RECEIVER',
            },
          ]
        }
      )
    }}>添加审核流程（最多50个）</div>}

  </div>


}

class CreateCodePrintingConfigStep2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
    this.props.loadAddressBookSelections({ status: 'ENABLE' });
    this.props.loadFactorySelections({ factoryType: 'PRINT', status: 'ENABLE' });
    this.props.loadUsersSelections({ status: 'ENABLE' });
    // this.props.loadIpcSelections({ status: "ENABLE", factoryId: "" })
    // console.log(this.props.ipcSelectionsList)
    // console.log(this.props.setCreateStep())

  }

  perStep = () => {
    const { setCreateStep, formValues } = this.props;
    setCreateStep({ step: 2, values: trims(formValues.values) });
    this.props.setCurrentStep(1);

  }

  next = (values) => {
    console.warn(values)
    this.props.setCreateStep({ step: 2, values: trims(values) });
    this.props.setCurrentStep(3);
  }

  canCancel = () => {
    this.setState({
      confirmStatus: true
    });
  }
  handleFactoryChange = (data) => {
    
    this.props.loadIpcSelections({ status: "ENABLE", factoryId: data.value })

  }
  cancel = () => {
    this.props.history.goBack();
  }

  render() {
    const { step0, handleSubmit, change, addressBookSelections = [], factorySelector, usersSelectionsOne, usersSelectionsTwo, ipcSelectionsList, initialValues } = this.props;
    let passwordComplexity = initialValues.passwordComplexity;

    return (

      <div className="step2">
        {step0.codeSourceType !== 'CODE_IMPORT' ?
          <FieldArray name="configWorkflowTemplateDTOs" change={change} usersSelectionsOne={usersSelectionsOne} usersSelectionsTwo={usersSelectionsTwo} ipcSelectionsList={ipcSelectionsList} factorySelector={factorySelector} addressBookSelections={addressBookSelections} handleFactoryChange={this.handleFactoryChange} initialValues={passwordComplexity} component={renderAudits} />
          :
          <div style={{ textAlign: 'center' }}>
            您使用的码源无需建立审核流程
        </div>
        }
        <div className="line" ></div>
        <div className="button-block" >
          <Button className="weak-button" onClick={this.canCancel} >取消</Button> <Button className="weak-button button-margin" onClick={this.perStep} >上一步</Button>  <Button className="button-margin" onClick={handleSubmit(this.next)} >下一步</Button>
          {(this.state.confirmStatus) && <TablePopConfirm
            strongText={'印刷规格暂未完成，确认离开吗？'}
            text={'离开后编辑内容不做保存，请谨慎操作'}
            styles={{ marginTop: -165 }}   // height: 154,
            button={['确定', '取消']}
            onOk={this.cancel}
            onCancel={() => { this.setState({ confirmStatus: false }) }}
          />}
        </div>
      </div>
    )
  }
}

let CreateCodePrintingConfigStep2Form = reduxForm({
  form: 'CreateCodePrintingConfigStep2Form', // a unique identifier for this form
  enableReinitialize: true,
})(CreateCodePrintingConfigStep2)

CreateCodePrintingConfigStep2Form = connect(
  state => ({
    initialValues: state.codePrintingConfig.createStep2,
    step0: state.codePrintingConfig.createStep0,
    addressBookSelections: state.addressBooks.addressBookSelections,
    factorySelector: state.factory.selection,
    usersSelectionsOne: state.users.usersSelectionsOne,
    usersSelectionsTwo: state.users.usersSelectionsTwo,
    formValues: state.form.CreateCodePrintingConfigStep2Form,
    ipcSelectionsList: state.certificate.ipcSelectionsList
  }),
  {
    setCurrentStep,
    setCreateStep,
    loadAddressBookSelections: loadAddressBookSelections.REQUEST,
    loadFactorySelections: loadSelections.REQUEST,
    loadUsersSelections: loadUsersSelections.REQUEST,
    loadIpcSelections: loadIpcSelections.REQUEST,

  }
)(CreateCodePrintingConfigStep2Form)

CreateCodePrintingConfigStep2Form = withRouter(CreateCodePrintingConfigStep2Form);

export default CreateCodePrintingConfigStep2Form;
