import React from 'react';
import { connect } from 'react-redux';
import { Tip } from 'tyb';
const { setNavBar } = iceStarkData.store.get('commonAction')?.ui;
import ActivitySteps from '../../../ActivityManagement/ActivityManagement/Activity/ActivitySteps';
const { tagstatusSelections } = iceStarkData.store.get('commonAction')?.links;
import Step0 from './step0';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';

import './index.scss';

@connect(
  state => ({
    currentStep: state.codePrintingConfig.currentStep,
    Step2formValues: state.form.CreateCodePrintingConfigStep2Form,
    tagstatusSelection: state.links.tagstatusSelections1 || [],
  }),
  {
    //码状态
    tagstatusSelections: tagstatusSelections.REQUEST,
    setNavBar
  }
)
class CreateCodePrintingConfig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      steps: ['选择印刷类型', '填写信息配置', '建立审核流程', '设置预警参数']
    }
  }

  componentDidMount() {
    this.props.setNavBar('新增码印刷规格');
    //码状态
    // 迎驾贡注释
    // this.props.tagstatusSelections({ data: {}, status: 'ENABLE' });
  }


  render() {
    const { currentStep, Step2formValues } = this.props;
    const { steps } = this.state;
    return (
      <React.Fragment>
        {Step2formValues && Step2formValues.values && Step2formValues.values.configWorkflowTemplateDTOs &&
          Step2formValues.values.configWorkflowTemplateDTOs.length === 20 && <Tip style={{ marginTop: 20 }} hasClose={false} type="warning">
            一个码印刷配置最多可添加20个审核流程！
                    </Tip>}
        <div className="CreateCodePrintingConfig">
          <ActivitySteps
            current={currentStep}
            titles={steps}
          />
          {currentStep === 0 &&
            <Step0 />
          }

          {currentStep === 1 &&
            <Step1 tagstatusSelection={this.props.tagstatusSelection} />
          }

          {currentStep === 2 &&
            <Step2 />
          }

          {currentStep === 3 &&
            <Step3 />
          }

        </div>
      </React.Fragment>
    )
  }
}

export default CreateCodePrintingConfig;