import React from 'react';
import { reduxForm, FieldArray } from 'redux-form';
import { withRouter } from "react-router";
import { connect } from 'react-redux';
import {
  RadioButtonGroup, RadioGroup, Button, Input, FormGroupList
} from 'tyb';
import { TablePopConfirm } from 'ucode-components';

import { FormGroupField } from 'tyb';
const { setCurrentStep, addConfig, setCreateStep } = iceStarkData.store.get('commonAction')?.codePrintingConfig;
const { loadAddressBookSelections } = iceStarkData.store.get('commonAction')?.addressBooks;
const { loadSelections } = iceStarkData.store.get('commonAction')?.factory;
const { domainConfigsByEseId } = iceStarkData.store.get('commonAction')?.domainConfigs;
import * as validation from './validation';



class CreateCodePrintingConfigStep3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      oldcreateStep2: ''
    }
  }

  componentDidMount() {

  }

  perStep = () => {
    const { setCreateStep, formValues, createStep0 } = this.props;
    setCreateStep({ step: 3, values: formValues.values });
    if (this.state.oldcreateStep2 && createStep0.codeSourceType !== 'CODE_IMPORT') {
      let createStep2 = { ...this.state.oldcreateStep2 }
      this.state.oldcreateStep2.configWorkflowTemplateDTOs.forEach((it, index) => {
        if (it.workflowNodes.length == 0) {
          it.workflowNodes = [{ nodeCategory: "USER", nodeType: "PROCESS_START", signerCode: { text: "ISV账号", value: -1, disabled: '' } }, { nodeCategory: "USER", nodeType: "PROCESS_AUDIT" }, { nodeCategory: "USER", nodeType: "PACKET_RECEIVER" }, { nodeCategory: "USER", nodeType: "PASSWORD_RECEIVER" }]
        }
        createStep2.configWorkflowTemplateDTOs[index] = it
      })
      setCreateStep({ step: 2, values: createStep2 });
      this.setState({ oldcreateStep2: {} })
    }

    this.props.setCurrentStep(2);
  }

  next = (values) => {
    const { createStep0, createStep1, createStep2 } = this.props;
    let params = {};
    this.setState({ oldcreateStep2: { ...createStep2 } })
    let newcreateStep2 = createStep2
    if (createStep0.codeSourceType !== 'CODE_IMPORT') {
      createStep2.configWorkflowTemplateDTOs.map((it, index) => {
        let flag = false, it1 = {};
        it.workflowNodes.map(item => {
          if (item.nodeType == 'PROCESS_START' && item.signerCode.value == -1) {
            flag = true
          }
        })
        if (flag) { it1 = { ...it, workflowNodes: [] } } else { it1 = { ...it } }
        createStep2.configWorkflowTemplateDTOs[index] = it1
      })
    }

    // console.log(newcreateStep2)
    if (createStep0.combinationTagType == 'SAME_LEVEL_TAG') {
      params.combinationConfigDtos = [];
      let name1List = JSON.parse(createStep0.name1List.historyTagTemplateConfig.facedsData)[0];
      let name2List = JSON.parse(createStep0.name2List.historyTagTemplateConfig.facedsData)[0];

      params.combinationConfigDtos.push({
        eseConfigId: createStep0.name1,
        count: name1List.count,
        position: name1List.position,
        level: name1List.level,
      })
      params.combinationConfigDtos.push({
        eseConfigId: createStep0.name2,
        count: name2List.count,
        position: name2List.position,
        level: name2List.level,

      })

      if (createStep0.conditionKeys == 3) {
        let name3List = JSON.parse(createStep0.name3List.historyTagTemplateConfig.facedsData)[0];
        params.combinationConfigDtos.push({
          eseConfigId: createStep0.name3,
          count: name3List.count,
          position: name3List.position,
          level: name3List.level,

        })

      }


      params = JSON.parse(JSON.stringify({
        combinationTagType: 'SAME_LEVEL_TAG',
        ...params,
        ...createStep1,
        ...newcreateStep2,
        ...values,
      }));

    }
    else if (createStep0.combinationTagType == 'MULTI_LEVEL_TAG') {
      params.combinationConfigDtos = [];
      let name1List = JSON.parse(createStep0.name2_1List.historyTagTemplateConfig.facedsData)[0];
      let name2List = JSON.parse(createStep0.name2_2List.historyTagTemplateConfig.facedsData)[0];

      params.combinationConfigDtos.push({
        eseConfigId: createStep0.name2_1,
        count: 1,
        position: name1List.position,
        level: name1List.level,
      })
      params.combinationConfigDtos.push({
        eseConfigId: createStep0.name2_2,
        count: createStep0.count,
        position: name2List.position,
        level: name2List.level,

      })

      if (createStep0.batchs >= 2) {
        let name3List = JSON.parse(createStep0.name2_3List.historyTagTemplateConfig.facedsData)[0];
        params.combinationConfigDtos.push({
          eseConfigId: createStep0.name2_3,
          count: createStep0.count,
          position: name3List.position,
          level: name3List.level,

        })

      }
      if (createStep0.batchs >= 3) {
        let name4List = JSON.parse(createStep0.name2_4List.historyTagTemplateConfig.facedsData)[0];
        params.combinationConfigDtos.push({
          eseConfigId: createStep0.name2_4,
          count: createStep0.count,
          position: name4List.position,
          level: name4List.level,

        })

      }

      params = JSON.parse(JSON.stringify({
        combinationTagType: 'MULTI_LEVEL_TAG',
        multiCodeAsc: createStep0.multiCodeAsc,
        ...params,
        ...createStep1,
        ...createStep2,
        ...values,
      }));

    }
    else {
      params = JSON.parse(JSON.stringify({
        ...createStep0,
        ...createStep1,
        ...newcreateStep2,
        ...values,
      }));
    }

    //  return
    if (params.codeSourceType == 'CODE_IMPORT') {
      delete params.configWorkflowTemplateDTOs;
    } else {
      const configWorkflowTemplateDTOs = params.configWorkflowTemplateDTOs;
      // configWorkflowTemplateDTOs.passwordComplexity=(configWorkflowTemplateDTOs.passwordComplexity=="IPC")? configWorkflowTemplateDTOs.passwordComplexity: configWorkflowTemplateDTOs.passwordComplexity
      configWorkflowTemplateDTOs.forEach(v => {
        // console.log(v)
        v.factoryId = v.factoryId.value ? v.factoryId.value : v.factoryId;
        if (v.ipcId) {
          v.ipcId = v.ipcId.value
        } else {
          v.ipcId = ""
        }
        // console.log(v.workflowNodes)
        if (v.workflowNodes && v.workflowNodes.length > 0) {
          v.workflowNodes.forEach(v2 => {
            if (v2.signerCode) {
              v2.signerCode = v2.signerCode.value ? v2.signerCode.value : "-1"
            }

          })
          if (v.workflowNodes[3].signerCode == undefined || !v.workflowNodes[3].signerCode) {
            v.workflowNodes.splice(3, 1);
          }
          if (v.workflowNodes[2].signerCode == undefined || !v.workflowNodes[2].signerCode) {
            v.workflowNodes.splice(2, 1);
          }
          if (v.workflowNodes[1].signerCode == '-1' || v.workflowNodes[1].signerCode == "undefind") {
            v.workflowNodes.splice(1, 1);
          }
        }


      });
    }

    params.multiCodeAsc == '-1' ? params.multiCodeAsc = false : '';
    if (params.tagInitStatus == 'default') { params.tagInitStatus = '' }
    this.props.addConfig({ params, history: this.props.history });
  }

  canCancel = () => {
    this.setState({
      confirmStatus: true
    });
  }

  cancel = () => {
    this.props.history.goBack();
  }

  requiredByInput = validation.required('请填写')

  max99999 = validation.max(99999);

  min1 = validation.min(1);
  min0 = validation.min(0);
  render() {
    const { handleSubmit, change, addressBookSelections, factorySelector, permissionIds, createStep1 } = this.props;
    const inputStyle = {
      width: 70,
      fontSize: 12,
      marginLeft: 10,
      marginRight: 10
    };
    return (
      <div className="step3">
        <div style={{ color: '#000000' }}>设置预警参数</div>
        <table>
          <thead>
            <tr>
              <th className="table-padding">预警事件</th>
              <th>预警参数设置</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="table-padding">码生成后未激活</td>
              <td>
                <div>
                  <FormGroupField
                    label="码生成后"
                    name="genNoactiveWntime"
                    component={Input}
                    style={inputStyle}
                    validate={[this.requiredByInput, validation.isNumber, this.min1, this.max99999]}
                  />
                </div>
                <div>小时 未设置激活策略预警</div>
              </td>
            </tr>
            <tr>
              <td className="table-padding">登记后未激活</td>
              <td>
                <div>
                  <FormGroupField
                    label="码信息录入后"
                    name="regNoactiveWntime"
                    component={Input}
                    style={inputStyle}
                    validate={[this.requiredByInput, validation.isNumber, this.min1, this.max99999]}
                  />
                </div>
                <div>小时 未设置激活策略预警</div>
              </td>
            </tr>

            <tr>
              <td className="table-padding">发码后未下载</td>
              <td>
                <div>
                  <FormGroupField
                    label="码包生成发送后"
                    name="downloadNoactiveWntime"
                    component={Input}
                    style={inputStyle}
                    validate={[this.requiredByInput, validation.isNumber, this.min1, this.max99999]}
                  />
                </div>
                <div>小时 未下载码包策略预警</div>
              </td>
            </tr>
            {createStep1 && createStep1.passwordComplexity == 'IPC_SECRECY' &&
              <tr>
                <td className="table-padding">解码设备离线</td>
                <td>
                  <div>
                    <FormGroupField
                      label="码包生成发送后"
                      name="ipcNoDownloadWntime"
                      component={Input}
                      style={inputStyle}
                      validate={[this.requiredByInput, validation.isNumber, this.min1, this.max99999]}
                    />
                  </div>
                  <div>小时 未拉取码包预警</div>
                </td>
              </tr>}


          </tbody>
        </table>

        <div className="line" ></div>
        <div className="button-block" >
          <Button className="weak-button" onClick={this.canCancel} >取消</Button> <Button className="weak-button button-margin" onClick={this.perStep} >上一步</Button>  {permissionIds.includes('code.tagConfig.add') && <Button className="button-margin" onClick={handleSubmit(this.next)} >完成</Button>}
          {(this.state.confirmStatus) && <TablePopConfirm
            strongText={'印刷规格暂未完成，确认离开吗？'}
            text={'离开后编辑内容不做保存，请谨慎操作'}
            styles={{ marginTop: -165 }}   // height: 154,
            button={['确定', '取消']}
            onOk={this.cancel}
            onCancel={() => { this.setState({ confirmStatus: false }) }}
          />}
        </div>
      </div>
    )
  }
}

let CreateCodePrintingConfigStep3Form = reduxForm({
  form: 'CreateCodePrintingConfigStep3Form', // a unique identifier for this form
  enableReinitialize: true,
})(CreateCodePrintingConfigStep3)

CreateCodePrintingConfigStep3Form = connect(
  state => ({
    initialValues: state.codePrintingConfig.createStep3,
    createStep0: state.codePrintingConfig.createStep0,
    createStep1: state.codePrintingConfig.createStep1,
    createStep2: state.codePrintingConfig.createStep2,
    formValues: state.form.CreateCodePrintingConfigStep3Form,
    permissionIds: state.users.permissionIds || [],
  }),
  {
    setCurrentStep,
    setCreateStep,
    addConfig: addConfig.REQUEST
  }
)(CreateCodePrintingConfigStep3Form)

CreateCodePrintingConfigStep3Form = withRouter(CreateCodePrintingConfigStep3Form);

export default CreateCodePrintingConfigStep3Form;
